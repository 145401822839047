import React, { useContext, useState, useEffect } from "react";
import { BodyImg, TittlePage, SubTittle, StyledCarousel, CardFilms, MidPosition, ButtonDisplay, MidImg, BodyImgTrailer, ContainerMidPosition, ContaiterTitlePage } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import MenuHome from "../../../Components/Menu";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'primereact/carousel';
import fundohomepage from "../../../assests/imgs/fundo-homepage.png";
import fundohomepagevideo from "../../../assests/videos/happiestSeasonTrailerPTBR.mp4";
import texthomepage from "../../../assests/imgs/logomovie.svg"
import glee from "../../../assests/imgs/Glee.png"
import orange from "../../../assests/imgs/Série - Orange is the nwe black.jpg"
import theL from "../../../assests/imgs/The-L-World.jpg"
import wynonna from "../../../assests/imgs/Série - Wynonna Earp.jpg"
import imNot from "../../../assests/imgs/Série - I'm not okay with this.png"
import everything from "../../../assests/imgs/Série - Everything Sucks.png"
import addIcon from '../../../assests/icons/iconfavorit.png'
import ButtonGradient from "../../../Components/Buttons/ButtonGradient";
import ButtonBranco from "../../../Components/Buttons/ButtonBranco";
import Footer from "../../../Components/Footer";

const Series = () => {
  const { userInfos } = useContext(GlobalStateContext);

  const navigate = useNavigate();

  const [isVideoEnded, setIsVideoEnded] = useState(false);


  const handleVideoEnd = () => {
    setIsVideoEnded(true);
  };


  const mockProducts = [
    {
      id: 1,
      name: "Everything Sucks",
      image: everything
    },
    {
      id: 2,
      name: "Glee",
      image: glee
    },
    {
      id: 3,
      name: "Orange Is The New Black",
      image: orange
    },
    {
      id: 1,
      name: "The L Word",
      image: theL
    },
    {
      id: 2,
      name: "Wynonna",
      image: wynonna
    },
    {
      id: 3,
      name: "I'm Not Okay With This",
      image: imNot
    },

  ];


  const [products, setProducts] = useState(mockProducts);
  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];



  const productTemplate = (product) => {
    return (
      <div >
        <div>
          <CardFilms
            style={{
              width: "96%",
              height: "214px",
              borderRadius: "20px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.6)",
              cursor: "pointer"
            }}
            src={`${product.image}`}
            alt={product.name}
          />
          <div style={{ position: "relative", left: '80%', bottom: 70, height: 50, width: 50, backgroundColor: "transparent", cursor: 'pointer' }} >
            <img style={{ height: 40, width: 40 }} src={addIcon}></img>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Este código irá verificar se o vídeo terminou e irá trocar para a imagem estática.
    const video = document.getElementById('background-video');
    if (video) {
      video.addEventListener('ended', handleVideoEnd);
    }

    // Cleanup function para remover o event listener
    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  return (
    <>
      <BodyImgTrailer>

        {isVideoEnded ? (
          // Exibe a imagem estática após o vídeo ter terminado
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img style={{ height: '100%', width: '100%' }} src={fundohomepage} alt="Fundo Estático" />
            <div style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "30%",
              backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1.5))"
            }}></div>
          </div>
        ) : (
          // Renderiza o vídeo antes de terminar
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <video
              id="background-video"
              autoPlay
              muted
              onEnded={handleVideoEnd}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}>
              <source src={fundohomepagevideo} type="video/mp4" />
            </video>
            <div style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "30%",
              backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1.6))"
            }}></div>
          </div>
        )}
        <ContainerMidPosition>
          <MidPosition>
            <MidImg src={texthomepage} />
          </MidPosition>
          <ButtonDisplay>
            <ButtonGradient onClick={() => navigate('/movieScreen')} />
            <ButtonBranco onClick={() => navigate('/infos')} />
          </ButtonDisplay>

        </ContainerMidPosition>
      </BodyImgTrailer>
      <BodyImg>
        <MenuHome />
        <ContaiterTitlePage>
          <TittlePage>
            SÉRIES
          </TittlePage>
          <SubTittle>Se está disponível, por que não assistir tudo de uma vez mesmo? Sem pressão!</SubTittle>
        </ContaiterTitlePage>
        <StyledCarousel>
          <div className="card">
            <Carousel value={products} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
              itemTemplate={productTemplate} showIndicators={false} />
          </div>
        </StyledCarousel>
        <Footer/>
      </BodyImg>
    </>
  )
}

export default Series;