import styled from "styled-components";


export const BodyImg = styled.div`
    height: 100vh;
    width: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative; // Necessário para posicionar corretamente o pseudo-elemento

    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1.5) 0%, 
            rgba(0, 0, 0, 0) 25%,
            rgba(0, 0, 0, 0) 75%, 
            rgba(0, 0, 0, 0.7) 100%
        ); // Cria o esfumaçado preto na parte superior e inferior
        z-index: -1; // Coloca o esfumaçado atrás do conteúdo
    }
`;



export const Body = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 10%, #9a413c 100%);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    z-index: 1;
`
export const DivideBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

`

export const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out; // Adiciona uma transição suave
    cursor: pointer;

    &:hover {
        transform: translateY(-5px); // Move o Box para cima ao passar o mouse
        box-shadow: 0 10px 20px rgba(0,0,0,0.2); // Adiciona uma sombra para o efeito de flutuação
    }
`
export const BoxNoHover = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;


`



export const TittlePage = styled.text`
font-style: normal;
font-weight: 300;
font-size: 60px;
line-height: 29px;
letter-spacing: 0.2em;
color: white;
margin-top: 200px;
`
export const TittlePageName = styled.text`
font-style: normal;
font-weight: 100;
font-size: 30px;
line-height: 29px;
letter-spacing: 0.2em;
color: white;
margin-top: 20px;
`

export const ContainerMidPosition = styled.div`
    position: absolute;
    bottom: -250px;
    margin-bottom: 50; 
    display: flex;
    align-items: center; 
    gap: 50px;

        @media (max-width: 1024px) {
    flex-direction: column;
    bottom: -200px;
  }

`

export const MidPosition = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;



`
export const MidImg = styled.img`
    height: 200px;
    width: 450px;
    margin-left: 5%;

                @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }

`

export const ButtonDisplay = styled.div`
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    gap: 19px;

            @media (max-width: 425px) {
    flex-direction: column
`;


export const TextBody = styled.text`
  color: white;
 font-family: 'TypoSlab Irregular Demo', serif;
  font-style: normal;
    font-weight: 300;
    font-size: 40px;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;

`;


export const CardFilms = styled.img`
  transition: border 0.1s ease-in-out;

  &:hover {
    border: 3px solid white; // Adiciona uma borda branca ao passar o mouse  
  }

`;