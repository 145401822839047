import styled from "styled-components";

export const BodyImg = styled.div`
  height: 100vh;
  width: 100%;
  background: #000000 no-repeat center/cover;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
`;

export const Body = styled.div`
  width: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const BoxNoHover = styled.div`

  width: 100%;

`;


export const TextBody = styled.div`
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  line-height: 24.3px;
`;

export const ButtonImg = styled.div`
  cursor: pointer;
  img:hover {
    opacity: 0.5;
  }
`;

export const DivLogo = styled.div`
  width: 100%;
`;

export const DivButtons = styled.div`
  width: 100%;
`;

export const DivideBody = styled.div`

  width: 100%;

`;

export const DivSinopse = styled.div`

  width: 65%;
  margin-top: 20px;
  color: white;

`;

export const DivInfo = styled.div`

  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  gap: 20px;

`;


