import styled from "styled-components";

export const BodyImg = styled.div`
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(0deg,#9a413c 0%, #000000 100%);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    z-index: 1;
`;


export const BodyImgTrailer = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(0deg,#9a413c 0%, #000000 100%);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    z-index: 1;
  



`;

export const ContaiterTitlePage = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-top: 100px;

          @media (max-width: 1024px) {
      margin-left: 0px;
  }
`

export const TittlePage = styled.text`
    font-style: normal;
    font-size: 150px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    color: white;
    margin-top: 150px;

    
          @media (max-width: 1024px) {
      font-size: 50px;
      text-align: center;
  }
`;

export const SubTittle = styled.text`
    width: 386px;
    font-style: normal;
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    color: white;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;

              @media (max-width: 1024px) {
      width: 100%;
  }

`;

export const StyledCarousel = styled.div`
    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background: #cc005b;
        color: #ffffff;
    }
`;

export const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    margin: 2%;
    min-height: 300px;

    img, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 96%;
        height: 100%;
        border-radius: 0px 30px 0px 0px;
        object-fit: cover;
    }

    video {
        display: none;
    }

    .film-title {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: black;
        background: rgba(0, 0, 0, 0.5);
        padding: 5px;
        border-radius: 5px;
    }
`;

export const CardFilms = styled.img`
  transition: border 0.1s ease-in-out;

  &:hover {
    border: 3px solid white; // Adiciona uma borda branca ao passar o mouse  
  }

`;


export const ContainerMidPosition = styled.div`
    position: absolute;
    bottom: -250px;
    margin-bottom: 50; 
    display: flex;
    align-items: center; 
    gap: 50px;

        @media (max-width: 1024px) {
    flex-direction: column;
    bottom: -200px;
  }

`


export const MidPosition = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;



`
export const MidImg = styled.img`
    height: 200px;
    width: 450px;
    margin-left: 61px;

            @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }
`

export const ButtonDisplay = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 69px;
    cursor: pointer;
    gap: 19px;

            @media (max-width: 425px) {
    flex-direction: column;
`;