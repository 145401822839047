import React, { useState } from "react";
import { Body, LogoB, ButtonRegister, CardPlan, TittleCard, Price, TittlePack, CardEnglobe } from "./styled";
import { SkewLoader } from "react-spinners";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import logo from "../../../assests/imgs/simbolosemnomebranco.png"
import Footer from "../../../Components/Footer";



const Plans = () => {
    const [register, setRegister] = useState({
        name: null,
        email: null,
        password: "",
        phone: null
    });
    const [checkPassword, setCheckPassword] = useState("");
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();



    return (
        <Body>
            {loading ?
                <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", zIndex: 10 }}>
                    <SkewLoader
                        color="#A40464"
                        loading={true}
                        size={50}
                    />
                </div>
                : null
            }

            <div style={{ textAlign: "center" }}>
                <LogoB src={logo} />
                <p style={{ color: "#FFFFFF" }}>PASSO 1 DE 3</p>
            </div>

            <TittleCard>QUAL O SEU PLANO IDEAL?</TittleCard>

            <div style={{ display: "flex", flexDirection: "column" }}>

                {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <ul style={{ color: "#FFFFFF", display: "flex", flexDirection: "column", gap: "15px", width: "309px" }}>
                        <li>Aproveite em todos os seus dispositivos.</li>
                        <li>Tenha conteúdo em alta definição, com qualidade 4k.</li>
                    </ul>

                    <ul style={{ color: "#FFFFFF", display: "flex", flexDirection: "column", gap: "15px", width: "309px" }}>
                        <li>Crie até 4  perfis diferentes.</li>
                        <li>Baixe seus filmes e séries preferidos e assista onde e quando quiser.</li>
                    </ul>


                </div> */}

                <div style={{ display: "flex", gap: "70px" }}>

                    <CardEnglobe>
                        <TittlePack>PACOTES COMPLETOS</TittlePack>
                        <div style={{ display: "flex", gap: "70px",  justifyContent: "center"}}>
                            <CardPlan>
                                <TittleCard>PLANO GRATUITO</TittleCard>
                                <Price>R$ 0,00<p style={{ fontSize: "20px", fontWeight: "500", lineHeight: "19px", letterSpacing: "0em", textAlign: "left" }}>/mês</p> </Price>
                                <ul style={{ color: "#FFFFFF", display: "flex", flexDirection: "column", gap: "15px" }} >
                                    <li style={{ width: "268.92px" }}>Venha conhecer nossa plataforma.</li>
                                    {/* <li style={{ width: "268.92px" }}>Brindes de fidelidade: primeira retirada 3 anos de assinatura; demais retiradas a cada 2 anos.</li> */}
                                </ul>
                                <ButtonRegister onClick={() => navigate('/register')}>É ESSE!</ButtonRegister>

                            </CardPlan>
                            {/* <CardPlan>
                                <TittleCard>PLANO CHÁ</TittleCard>
                                <Price>R$ 30,99 <p style={{ fontSize: "20px", fontWeight: "500", lineHeight: "19px", letterSpacing: "0em", textAlign: "left" }}>/mês</p> </Price>
                                <ul style={{ color: "#FFFFFF", display: "flex", flexDirection: "column", gap: "15px" }} >
                                    <li style={{ width: "268.92px" }}>Assista em até 2 telas simultaneamente</li>
                                    <li style={{ width: "268.92px" }}>Brindes de fidelidade: primeira retirada 3 anos de assinatura; demais retiradas a cada 2 anos.</li>
                                </ul>
                                <ButtonRegister onClick={() => navigate('/register')}>É ESSE!</ButtonRegister>

                            </CardPlan> */}
                            {/* 
                            <CardPlan>
                                <TittleCard>PLANO BREJO</TittleCard>
                                <Price>R$ 45,99 <p style={{ fontSize: "20px", fontWeight: "500", lineHeight: "19px", letterSpacing: "0em", textAlign: "left" }}>/mês</p> </Price>
                                <ul style={{ color: "#FFFFFF", display: "flex", flexDirection: "column", gap: "15px" }}>
                                    <li style={{ width: "268.92px" }}>Assista em até 4 telas simultaneamente</li>
                                    <li style={{ width: "268.92px" }}>Brindes de fidelidade: primeira retirada com 2 anos de assinatura; demais retiradas a cada 2 anos.</li>
                                </ul>
                                <ButtonRegister onClick={() => navigate('/register')}>É ESSE!</ButtonRegister>

                            </CardPlan> */}
                        </div>
                    </CardEnglobe>

                    {/* <div style={{    border: "1px solid #FFFFFF", borderRadius: "20px", padding: "20px"}}>

                        <TittlePack>PACOTES CONTEÚDOS</TittlePack>

                        <CardPlan>
                            <TittleCard>PLANO SAPPHO</TittleCard>
                            <Price>R$ 9,90 <p style={{ fontSize: "20px", fontWeight: "500", lineHeight: "19px", letterSpacing: "0em", textAlign: "left" }}>/mês</p> </Price>
                            <ul style={{ color: "#FFFFFF", display: "flex", flexDirection: "column", gap: "15px" }}>
                                <li style={{ width: "268.92px" }}>Acesso a todos os conteúdos exclusivos (curtas, entrevistas, fanmeting, etc)
                                    sem acesso aos filmes e séries no streaming.</li>
                                <li style={{ width: "268.92px" }}>Não se enquadra ao programa de fidelidade.</li>
                            </ul>
                            <ButtonRegister onClick={() => navigate('/register')}>É ESSE!</ButtonRegister>

                        </CardPlan>

                    </div> */}
                </div>

            </div>
            <Footer/>
        </Body>
    )
}

export default Plans;