import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CarouselComponent from "./CarouselComponent";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import ButtonGradient from "../../../Components/Buttons/ButtonGradient";
import Therm from "../../../Components/Thermometer";
import { BodyImg, Body, DivideBody, BoxNoHover, TextBody, DivLogo, DivButtons, DivInfo, DivSinopse, ButtonImg, OverlayImage3 } from "./styled";

import fundoHomepageVideo from "../../../assests/videos/happiestSeasonTrailerPTBR.mp4";
import fundoHomepageImg from "../../../assests/imgs/fundo-homepage.png";

import seta from "../../../assests/imgs/setaLongaAcima.png";
import logomovie from "../../../assests/imgs/logomovie.svg";
import iconfilm from "../../../assests/imgs/iconfilm.png";
import iconclass from "../../../assests/imgs/incon-class.png";
import Footer from "../../../Components/Footer";


const InfoContent = ({ products, responsiveOptions, productTemplate }) => {
  const { userInfos } = useContext(GlobalStateContext);
  const navigate = useNavigate();

  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const handleVideoEnd = () => setIsVideoEnded(true);

  const [backgroundImage, setBackgroundImage] = useState(fundoHomepageImg);

  useEffect(() => {
    const video = document.getElementById("background-video");
    if (video) video.addEventListener("ended", handleVideoEnd);

    return () => video?.removeEventListener("ended", handleVideoEnd);
  }, []);

  return (
    <>
      <BodyImg>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img style={{ height: '100%', width: '100%' }} src={fundoHomepageImg} alt="Fundo Estático" />
            <div style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "30%",
              backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1.5))"
            }}></div>
          </div>
        

        <div style={{ position: "absolute", top: "10px", marginLeft: "35px" }}>
          <ButtonImg onClick={() => navigate("/")}>
            <img src={seta} alt="previousPage" />
          </ButtonImg>
        </div>

        <div style={{ position: "absolute", top: "50%", marginLeft: "85px", width: "80%", zIndex: "3" }}>
          <DivLogo><img src={logomovie} alt="Logo do filme" /></DivLogo>
          <DivButtons>
            <ButtonGradient text={"Assistir"} onClick={() => navigate("/movieScreen")} />
            <Therm />
          </DivButtons>
          <DivInfo>
            <img src={iconfilm} alt="Ícone de filme" />
            <TextBody>1h42 min</TextBody>
            <img src={iconclass} alt="Ícone de classificação" />
            <TextBody>2020</TextBody>
            <TextBody>Drama</TextBody>
          </DivInfo>
          <DivSinopse>
            As namoradas Harper e Abby visitam a família de Harper para o jantar
            anual de Natal. No entanto, logo após chegar, Abby percebe que a
            moça tem mantido seu relacionamento em segredo de seus pais
            conservadores.
          </DivSinopse>
        </div>
      </BodyImg>

      <Body>
        <DivideBody style={{ gap: "40px" }}>
          <BoxNoHover style={{ width: "45%", flexDirection: "column", justifyContent: "center", marginTop: "3%" }} />
        </DivideBody>
        <DivideBody>
          <TextBody style={{ fontSize: 25, color: "white", marginLeft: "50px" }}>
            Títulos Relacionados
          </TextBody>
          <CarouselComponent products={products} productTemplate={productTemplate} responsiveOptions={responsiveOptions} />
        </DivideBody>
      <Footer/>
      </Body>
    </>
  );
};

export default InfoContent;
