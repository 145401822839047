import React, { useContext, useEffect } from "react";
import { Body, ButtonImg, MidImg, Position, TextFidelity, TextMouthFidelity, TextMouth, DescriptionFidelity, RewardsUnlocked, TextRewards, Rewards, PositionContainer, ContainerMouthFidelity } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import claret from "../../../assests/imgs/Claret.png"
import profile from "../../../assests/imgs/profile.png"
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import seta from "../../../assests/imgs/setaLongaAcima.png"
import cadeadoAberto from "../../../assests/imgs/cadeadoAberto.png"
import cadeadoFechado from "../../../assests/imgs/cadeadoFechado.png"
import Footer from "../../../Components/Footer";


const Fidelity = () => {
    const { userInfos } = useContext(GlobalStateContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("aqui", userInfos)
    },[])

    return (
        <Body>
            
            <Position >
                <PositionContainer>
                    <ButtonImg onClick={() => navigate('/')}> 
                        <img src={seta}/>
                    </ButtonImg>
                        <MidImg src={profile}></MidImg>
                        <TextFidelity>OI,<br/> {userInfos?.user?.fullName}!</TextFidelity>
                    
                    <DescriptionFidelity>Você é membro desde 21 de fevereiro de 2023.</DescriptionFidelity>

                </PositionContainer>
            </Position>
            <div style={{ marginBottom: "50px"}}>
                <ContainerMouthFidelity >
                    <TextMouthFidelity>X meses </TextMouthFidelity> 
                    <TextMouth>tendo gay panic juntas!</TextMouth>
                </ContainerMouthFidelity >
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
                    <RewardsUnlocked>
                        <img src={cadeadoAberto}/>
                        <TextRewards>Recompensa xxxx desbloqueada</TextRewards>
                    </RewardsUnlocked>
                    <Rewards>
                        <img src={cadeadoFechado}/>
                        <TextRewards>Recompensa xxxx desbloqueada</TextRewards>
                    </Rewards>
                    <Rewards>
                        <img src={cadeadoFechado}/>
                        <TextRewards>Recompensa xxxx desbloqueada</TextRewards>
                    </Rewards>
                    <Rewards>
                        <img src={cadeadoFechado}/>
                        <TextRewards>Recompensa xxxx desbloqueada</TextRewards>
                    </Rewards>
                </div>
            </div>
            <Footer/>
        </Body>
    )
}

export default Fidelity;