import { FileUpload } from 'primereact/fileupload';
import styled from 'styled-components';

// Estilização do corpo da página
export const Body = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Estilização do container do formulário
export const FormContainer = styled.form`
  background-color: #212121;
  color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  

  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;

    & > * {
      flex: 1 1 calc(50% - 1rem);
      min-width: 200px;
    }
  }

  .form-row > *:nth-child(3) {
    flex: 1 1 100%;
  }

  .form-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    button{
      height: 45px;
      width: 100px;
      margin-top: 35px;
    }
   
  }
`;


export const Input = styled.input`
  border: 1px solid #a44d4a;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
`;

export const Select = styled.select`
  border: 1px solid #a44d4a;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  appearance: none; /* Remove a seta padrão do select */
  
  /* Adiciona uma seta personalizada (opcional) */
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath fill="%23a44d4a" d="M7 10l5 5 5-5z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.5rem center; /* Posiciona a seta à direita */
  background-size: 12px; /* Tamanho da seta */

  &:focus {
    outline: none; /* Remove o contorno padrão ao focar */
    border-color: #a44d4a; /* Mantém a mesma cor do border ao focar */
  }
`;

export const Textarea = styled.textarea`
  border: 1px solid #a44d4a;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
`;

export const Button = styled.button`
  background-color: #903D38;
  border: none;
  color: #ffffff;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #a44d4a;
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }
`;

// Estilização dos campos de upload de arquivos
export const StyledFileUpload = styled(FileUpload)`
  && {
    .p-fileupload {
      border: 1px solid #a44d4a;
      border-radius: 8px;
      background-color: #f1f1f1;
      margin-bottom: 1rem;
    }

    .p-fileupload-button {
      background-color: #903D38;
      border: none;
      color: #ffffff;
      border-radius: 4px;
      transition: background-color 0.3s ease;
    }

    .p-fileupload-button:hover {
      background-color: #a44d4a;
    }
    
  }
`;

export const FilmItem = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FilmDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RemoveButton = styled.button`
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #e74c3c;
    margin-left: 15px;
    color: white;
    cursor: pointer;
    &:hover {
        background-color: #c0392b;
    }
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
`;

export const ClassificationImage = styled.img`
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: ${(props) => (props.selected ? '2px solid #903D38' : 'none')};
  border-radius: 4px;
`;
