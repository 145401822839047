import React, { useState } from "react";
import { Body, CardLogin, InputRegister, TittleCard, LogoB, PaymentCard, PaymentOption, FormContainer, Input, Button, TextAss, DescriptionCard } from "./styled";
import { useNavigate } from 'react-router-dom';
import logo from "../../../assests/imgs/simbolosemnomebranco.png";
import visa from "../../../assests/icons/cartaotest.jpg";
import mastercard from "../../../assests/icons/cartaotest.jpg";
import amex from "../../../assests/icons/cartaotest.jpg";
import discover from "../../../assests/icons/cartaotest.jpg";
import elo from "../../../assests/icons/cartaotest.jpg";
import PlanCard from "../../../Components/PlanCard";
import Footer from "../../../Components/Footer";

const RegisterPag = () => {
    const [selectedCard, setSelectedCard] = useState('credit');
    const navigate = useNavigate();

    return (
        <Body>
            <div style={{ width: "100%",textAlign: "center", marginBottom: "20px" }}>
                <LogoB src={logo} />
                <p style={{ color: "#FFFFFF", margin: "10px 0" }}>PASSO 3 DE 3</p>
                <PlanCard name="Plano Chá" price="30,99" />
                <TittleCard>Adicione sua forma de pagamento</TittleCard>
                <div style={{textAlign: "-webkit-center"}}>
                <DescriptionCard>A sua assinatura só será validada após a escolha da forma de pagamento e o preenchimento das informações necessárias.</DescriptionCard>
                </div>
            </div>

            <PaymentCard>
                <p>Cartões de crédito</p>
                <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginBottom: "20px" }}>
                    <img src={visa} alt="Visa" style={{ width: "40px" }} />
                    <img src={mastercard} alt="Mastercard" style={{ width: "40px" }} />
                    <img src={amex} alt="Amex" style={{ width: "40px" }} />
                    <img src={discover} alt="Discover" style={{ width: "40px" }} />
                    <img src={elo} alt="Elo" style={{ width: "40px" }} />
                </div>
                <div>
                    <PaymentOption
                        selected={selectedCard === 'credit'}
                        onClick={() => setSelectedCard('credit')}
                    >
                        Cartão de Crédito
                    </PaymentOption>
                    <PaymentOption
                        selected={selectedCard === 'debit'}
                        onClick={() => setSelectedCard('debit')}
                    >
                        Cartão de Débito
                    </PaymentOption>
                </div>

                <FormContainer visible={selectedCard === 'credit'}>
                    <Input type="text" placeholder="Número do cartão" />
                    <Input type="text" placeholder="Nome do titular do cartão" />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Input type="text" placeholder="Data de vencimento" style={{ width: "48%" }} />
                        <Input type="text" placeholder="Código de segurança" style={{ width: "48%" }} />
                    </div>
                    <Input type="text" placeholder="CPF" />
                    <TextAss>No início da sua assinatura na Lezzes, faremos a cobrança no valor de R$ 30,99 e a assinatura será renovada de forma automática e recorrente todo mês pelo preço atual da ocasião, a menos que você cancele antes da renovação automática. O preço inclui os impostos aplicáveis. Para cancelar ou impedir a renovação automática da sua assinatura, acesse as configurações do seu perfil e escolha Assinatura. Selecione o botão abaixo para confirmar a sua assinatura.
                    </TextAss>
                    <Button onClick={() => navigate('/')}>Confirmar Assinatura</Button>
                </FormContainer>

                <FormContainer visible={selectedCard === 'debit'}>
                    <Input type="text" placeholder="Número do cartão" />
                    <Input type="text" placeholder="Nome do titular do cartão" />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Input type="text" placeholder="Data de vencimento" style={{ width: "48%" }} />
                        <Input type="text" placeholder="Código de segurança" style={{ width: "48%" }} />
                    </div>
                    <Input type="text" placeholder="CPF" />
                    <TextAss>No início da sua assinatura na Lezzes, faremos a cobrança no valor de R$ 30,99 e a assinatura será renovada de forma automática e recorrente todo mês pelo preço atual da ocasião, a menos que você cancele antes da renovação automática. O preço inclui os impostos aplicáveis. Para cancelar ou impedir a renovação automática da sua assinatura, acesse as configurações do seu perfil e escolha Assinatura. Selecione o botão abaixo para confirmar a sua assinatura.
                    </TextAss>
                    <Button onClick={() => navigate('/')}>Confirmar Assinatura</Button>
                </FormContainer>
            </PaymentCard>
            <Footer/>
        </Body>
    );
};


export default RegisterPag;
