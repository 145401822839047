import React from "react";
import { Foter, BodyText } from "./styled"
import logo from "../../assests/imgs/simbolosemnome.png";

const Footer = () => {

  return (
    <Foter >
      <img style={{ height: "100px" }} src={logo}/>
      <BodyText>

        <a style={{textDecoration: "none", color: "white"}} >Termo e Política de Privacidade</a>
        <a style={{textDecoration: "none", color: "white"}} href="/support">Contato</a>
        <a style={{textDecoration: "none", color: "white"}} >Ajuda</a>
        <p>Lezzes Entretenimento LTDA</p>

      </BodyText>
    </Foter>
  );
};

export default Footer;
