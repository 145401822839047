import styled from "styled-components";

export const ButtonG = styled.button`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    color: #FFF;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    border-radius: 10px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.88%, #FC9B54 100%);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);

    transition: opacity 0.3s ease; /* Adiciona uma transição suave para a opacidade */

    :hover {
        opacity: 0.5; /* Define a opacidade desejada */
    }
`;