import React, { useContext } from "react";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { useNavigate } from "react-router-dom";
import { Body, StyledIframe, VideoContainer } from "./styled";
import VimeoPlayer from "../../../Components/MovieScreen/vimeo";
import Footer from "../../../Components/Footer";
const MovieScreen = ({url}) => {
    const { userInfos } = useContext(GlobalStateContext);

    const navigate = useNavigate();

    return (
        <Body>
            <VideoContainer>
                <VimeoPlayer/>
            </VideoContainer>
            <Footer/>
        </Body>
    )
}

export default MovieScreen;