import styled from "styled-components";




export const Body = styled.div`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: linear-gradient(0deg,#9a413c 0%, #000000 100%);
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

export const CardEnglobe = styled.div`
border: 1px solid #FFFFFF;
border-radius: 20px;
padding: 20px ;

  @media (max-width: 375px) {
    padding: 2px;
  }
`

export const LogoB = styled.img`
width: 50px;
height: 96px;
`

export const CardPlan = styled.div`
    height: 374px;
    width: 90%;
    background: linear-gradient(178.92deg, rgba(150, 31, 1, 0.4) 0.93%, rgba(150, 31, 1, 0.207972) 22.78%, rgba(150, 31, 1, 0) 108.5%, rgba(150, 31, 1, 0.0598976) 108.5%);

    border: 1px solid #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const TittleCard = styled.text`
font-style: normal;
font-weight: 300;
font-size: 30px;
line-height: 29px;
letter-spacing: 0.2em;
text-align: center;
margin-top: 40px;

color: #FFFFFF;
margin-bottom: 30px;


`

export const TittlePack = styled.p`
font-style: normal;
font-weight: 300;
font-size: 25px;
line-height: 29px;
letter-spacing: 0.2em;
margin-top: 40px;
margin-bottom: 40px;
text-align: center;
color: #FFFFFF;


`

export const Price = styled.text`
display: flex;
font-style: normal;
font-size: 55px;
font-weight: 400;
line-height: 55px;
letter-spacing: 0.1em;
text-align: center;
margin-top: 10px;
margin-bottom: 15px;

color: #FFFFFF;

`

export const ButtonRegister = styled.button`
    width: 260.52px;
    height: 32.65px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.35%, #FC9B54 100%);
    border: none;
    border-radius: 5px;
    font-weight: 100;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.2em;
    cursor: pointer;
    color: #FFFFFF;

    :hover{
        color: #000;
    }

`
