import React, { useEffect, useState } from "react";
import GlobalStateContext from "./GlobalStateContext";

const GlobalState = (props) => {
    const [userInfos, setUserInfos] = useState("")
    const [logged, setLogged] = useState(false)

    localStorage.getItem("@token");

    useEffect(() => {
        if(localStorage.getItem("@token") !== 'null'){
            console.log(localStorage.getItem("@token"))
            setLogged(true)
        }else {
            setLogged(false)
        }
    },[])

    const datas = {
        setUserInfos,
        userInfos,
        logged,
        setLogged
    }



    return(
        <GlobalStateContext.Provider value={datas}>
            {props.children}
        </GlobalStateContext.Provider>
    )
}

export default GlobalState;