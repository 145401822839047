import styled from "styled-components";


export const Body = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #9a413c 0%, #000000 100%);
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

`

export const TextFidelity = styled.text`

color: white;
font-size: 120px;
font-weight: 400;
line-height: 70px;
text-align: center;
letter-spacing: 0,2em;
line-height: 1.2;

            @media (max-width: 1024px) {
    font-size: 60px;
  }

`

export const TextMouthFidelity = styled.p`
color: white;
font-size: 100px;
font-weight: 400;
line-height: 60px;
text-align: center;

            @media (max-width: 1024px) {
    font-size: 40px;
  }

`

export const TextMouth = styled.p`
color: white;
font-size: 50px;
font-weight: 500;
line-height: 60px;
text-align: left;

            @media (max-width: 1024px) {
    font-size: 40px;
  }

`

export const DescriptionFidelity = styled.p`
width: 300px;
color: white;
font-size: 30px;
font-weight: 500;
line-height: 29.16px;
letter-spacing: 0.2em;
text-align: center;

margin-left: 200px;

            @media (max-width: 1024px) {
    margin-left: 0px;
  }

`


export const TittlePageName = styled.text`
font-style: normal;
font-weight: 100;
font-size: 30px;
line-height: 29px;
letter-spacing: 0.2em;
color: white;
margin-top: 20px;
`


export const Position = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-top: 80px;

            @media (max-width: 1024px) {
    font-size: 30px;
  }

`

export const PositionContainer = styled.div`
display: flex;
width: 100%;
gap: 20px;

    @media (max-width: 1024px) {

        display: block;
  }

`

export const ContainerMouthFidelity = styled.div`
    display: flex;
    align-items: baseline;
    gap: 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
        margin: 20px;
         gap: 0px;
  }

`

export const MidImg = styled.img`
    height: 200px;
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
    border-radius: 20px;
    background-color: white;

        @media (max-width: 1024px) {
        height: 100px;
  }
`

export const ButtonImg = styled.div`
  display: flex;
  width: 10%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  :hover {
      opacity: 0.5; /* Define a opacidade desejada */
  }
`;

export const RewardsUnlocked = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.88%, #FC9B54 100%);
    width: 80%;
    align-items: center;
    justify-content: center;
`

export const Rewards = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
    background: #887D7D;
    width: 80%;
    align-items: center;
    justify-content: center;
`

export const TextRewards = styled.p`
    color: #FFFFFFA1;
    font-size: 20px;
    font-weight: 500;
    line-height: 19.44px;
    letter-spacing: 0.2em;
    text-align: center;

`