import React, { useEffect, useState } from "react";

import loaderVideo from "../../assests/videos/vinheta_loader.mp4";
import { LoaderContainer } from "./styled";

const Loader = ({ isLoading }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeInComplete, setFadeInComplete] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setFadeOut(false);
      setFadeInComplete(false);
    } else {
      setTimeout(() => {
        setFadeOut(true);
      }, 4350);
    }
  }, [isLoading]);


  return (
    <LoaderContainer>
      <video className={`${fadeOut ? "fade-out" : ""}`} autoPlay loop muted>
        <source src={loaderVideo} type="video/mp4" />
        Seu navegador não suporta o elemento de vídeo.
      </video>
    </LoaderContainer>
  );
};

export default Loader;
