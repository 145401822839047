import { Carousel } from 'primereact/carousel';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MenuHome from "../../../Components/Menu";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { BodyImg, ContaiterTitlePage, StyledCarousel, TittlePage } from "./styled";
import axios from 'axios';
import Footer from '../../../Components/Footer';

const Canais = () => {
    const { userInfos } = useContext(GlobalStateContext);
    const navigate = useNavigate();
    const API_KEY = 'SUA_CHAVE_API_YOUTUBE';

    const [channelsPodcasts, setChannelsPodcasts] = useState([]);
    const [channelsReacts, setChannelsReacts] = useState([]);
    const [playlists, setPlaylists] = useState([]);

    // IDs dos canais específicos para cada categoria
    const podcastChannelIds = ['UCxPodcastChannelID1', 'UCxPodcastChannelID2'];  // Substituir os IDs 
    const reactChannelIds = ['UCxReactChannelID1', 'UCxReactChannelID2'];        // Substituir os IDs 

    // Função para buscar dados dos canais com base nos IDs fornecidos
    const fetchChannelsByIds = async (channelIds, setState) => {
        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/channels`, {
                params: {
                    part: 'snippet',
                    id: channelIds.join(','),
                    key: API_KEY,
                },
            });
            setState(response.data.items);
        } catch (error) {
            console.error('Erro ao buscar canais:', error);
        }
    };

    // Função para buscar playlists de um canal
    const fetchPlaylistsByChannel = async (channelId) => {
        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/playlists`, {
                params: {
                    part: 'snippet',
                    channelId: channelId,
                    maxResults: 10,
                    key: API_KEY,
                },
            });
            setPlaylists(response.data.items); // Salva as playlists no estado
        } catch (error) {
            console.error('Erro ao buscar playlists:', error);
        }
    };

    // Ao clicar no canal, busca as playlists
    const handleChannelClick = (channelId) => {
        fetchPlaylistsByChannel(channelId);
        // Você pode redirecionar ou abrir um modal com as playlists
        // Exemplo: navigate(`/playlists/${channelId}`);
    };

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const channelTemplate = (channel) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3" onClick={() => handleChannelClick(channel.id)}>
                <div className="mb-3">
                    <img style={{ width: "96%", height:"214px", borderRadius: "0px 30px 0px 0px"}} src={channel.snippet.thumbnails.high.url} alt={channel.snippet.title} />
                    <h4>{channel.snippet.title}</h4>
                </div>
            </div>
        );
    };


    useEffect(() => {

        fetchChannelsByIds(podcastChannelIds, setChannelsPodcasts);

        fetchChannelsByIds(reactChannelIds, setChannelsReacts);
    }, []);

    return (
        <div style={{height: "100vh"}}>
            <BodyImg>
                <MenuHome />
                
                {/* Podcasts */}
                <ContaiterTitlePage>
                    <TittlePage>PODCASTS</TittlePage>
                </ContaiterTitlePage>
                <StyledCarousel>
                    <div className="card">
                        <Carousel value={channelsPodcasts} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular itemTemplate={channelTemplate} />
                    </div>
                </StyledCarousel>

                {/* Reacts */}
                <ContaiterTitlePage>
                    <TittlePage>REACTS</TittlePage>
                </ContaiterTitlePage>
                <StyledCarousel>
                    <div className="card">
                        <Carousel value={channelsReacts} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular itemTemplate={channelTemplate} />
                    </div>
                </StyledCarousel>

                
                <Footer/>
            </BodyImg>
        </div>
    )
}

export default Canais;
