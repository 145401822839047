import styled from "styled-components";

export const Body = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #000000 0%, #9a413c 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
`;

export const LogoB = styled.img`
    width: 50px;
    height: 96px;
`;

export const PaymentCard = styled.div`
    width: 90%;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
`;

export const TittleCard = styled.h2`
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
`;

export const DescriptionCard = styled.h2`
    width: 80%;
    font-size: 15px;
    font-weight: 500;
    line-height: 14.58px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
`;

export const PaymentOption = styled.div`
    cursor: pointer;
    margin: 10px 0;
    padding: 10px;
    background-color: ${({ selected }) => (selected ? '#ccc' : 'transparent')};
    border-radius: 5px;
    width: 100%;
    text-align: center;
    &:hover {
        background-color: #ddd;
    }
`;

export const FormContainer = styled.div`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    width: 100%;
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
`;

export const TextAss = styled.p`

font-size: 10px;
font-weight: 500;
line-height: 9.72px;
letter-spacing: 0.2em;
text-align: left;

`

export const Button = styled.button`
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    background: linear-gradient(180deg, #961F01 0%, #A40464 46.35%, #FC9B54 100%);
    cursor: pointer;

`;
