import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import styled from "styled-components";

import Loader from "../../../Components/Loader";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import api from "../../../services/api";
import { Body, ButtonLogin, CardLogin, CheckBox, CheckBoxText, ForgetButton, InputLogin, TittleCard } from "./styled";
import Footer from "../../../Components/Footer";


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [erro, setErro] = useState("");

  const { setUserInfos, setLogged } = useContext(GlobalStateContext);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const CustomCheckbox = styled(Checkbox)`
    .p-checkbox-box {
      background: linear-gradient(to right, #a40464, #f45b69);
      border: none;
    }

    .p-checkbox-box.p-highlight {
      background: linear-gradient(to right, #a40464, #f45b69);
    }

    .p-checkbox-box .p-checkbox-icon {
      color: white;
    }
  `;

  // Função para carregar dados do localStorage
  const loadStoredData = () => {
    const storedChecked = localStorage.getItem("@checked") === "true";
    if (storedChecked) {
      setChecked(true);
      setEmail(localStorage.getItem("@email") || "");
      setPassword(localStorage.getItem("@password") || "");
    }
  };
  

  // Função para salvar dados no localStorage
  const saveDataToLocalStorage = () => {
    if (checked) {
      localStorage.setItem("@checked", "true");
      localStorage.setItem("@email", email);
      localStorage.setItem("@password", password);
    } else {
      localStorage.removeItem("@email");
      localStorage.removeItem("@password");
      localStorage.setItem("@checked", "false");
    }
  };

  // Carrega os dados na montagem do componente
  useEffect(() => {
    loadStoredData();
  }, []);

  // Atualiza o localStorage sempre que o estado do "checked", "email" ou "password" mudar
  useEffect(() => {
    saveDataToLocalStorage();
  }, [checked, email, password]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const res = await api.post("/login", { email, password });
      localStorage.setItem("@token", res.data.token.token);
      setUserInfos(res.data);
      console.log("aquii",res.data)
      setLogged(true);
      navigate("/home");
    } catch (err) {
      setErro(err.response?.data?.error || "Erro ao realizar login.");
      setLogged(false);
    } finally {
      setLoading(false);
    }
  };

  
  const handleKeyPressEmail = (event) => {
    if (event.key === "Enter") {
      document.getElementById("passwordInput").focus(); // Move o foco para o campo de senha
    }
  };

  const handleKeyPressPassword = (event) => {
    if (event.key === "Enter") {
      handleLogin(); // Executa a função de login
    }
  };

  return (
    <Body>
      {loading ? (
        <Loader />
      ) : (
        <CardLogin>
            <div style={{ width: "80%", marginTop: 30 }}>
            <TittleCard>Entrar</TittleCard>
          </div>
          <InputText
            onKeyDown={handleKeyPressEmail}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite aqui o seu e-mail"
            style={{ marginTop: 30, width: "90%" }}
          />
          <div style={{ position: "relative", width: "90%", marginTop: 20 }}>
            <InputText
              id="passwordInput"
              type={passwordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPressPassword}
              placeholder="Digite aqui a sua senha"
              style={{ width: "100%" }}
            />
            <span
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                cursor: "pointer",
              }}
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? (
                <EyeOffIcon style={{ width: 20, height: 20 }} />
              ) : (
                <EyeIcon style={{ width: 20, height: 20 }} />
              )}
            </span>
          </div>
          <ButtonLogin id="loginInput" onClick={handleLogin}>
            Entrar
          </ButtonLogin>
          {erro && <p style={{ marginTop: 20, color: "#c1303a" }}>{erro}</p>}
          <ForgetButton style={{ marginTop: 10 }}>
            Esqueci minha senha
          </ForgetButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
              width: "100%",
              marginLeft: "10%",
            }}
          >
            <CustomCheckbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            />
            <CheckBoxText style={{ marginLeft: 10 }}>Lembrar meu acesso</CheckBoxText>
          </div>
          <div style={{ width: "100%", marginLeft: "10%", marginTop: 10 }}>
          <CheckBoxText style={{ fontSize: 13, marginTop: 30 }}>
              Ainda não faz parte da nossa comunidade?
            </CheckBoxText>
            <br/>
            <ForgetButton  style={{ fontSize: 13 }} onClick={() => navigate("/plans")}>
              Conheça nossos planos
            </ForgetButton>
          </div>
        </CardLogin>
      )}
      <Footer/>
    </Body>
  );
};

export default Login;
