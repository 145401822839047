import { animated, useSpring } from "@react-spring/web";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import profile from "../../../assests/imgs/profile.png";
import simbolosemnomebranco from "../../../assests/imgs/simbolosemnomebranco.png";
import ButtonGradientEdit from "../../../Components/Buttons/ButtonGradientEdit";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { Body, ButtonImg, ContainerHeader, MidImg, MidPosition, TittlePage, TittlePageName } from "./styled";
import Footer from "../../../Components/Footer";

const Home = () => {
  const { userInfos } = useContext(GlobalStateContext);
  const navigate = useNavigate();

  const fade = useSpring({
    from: { opacity: 0, x: -100 },
    to: { opacity: 1, x: 0 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    console.log("aqui", userInfos);
  }, [userInfos]);

  const handleGearClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    navigate("/profileManege");
  };

  return (
    <Body>
      <ContainerHeader>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {/* <img src={simbolosemnomebranco} alt="logo" /> */}
          <TittlePage>Quem está</TittlePage>
          <TittlePage>assistindo?</TittlePage>
        </div>
      </ContainerHeader>
      <MidPosition>
        <MidImg src={profile} onClick={() => navigate('/')}/>
        <TittlePageName>{userInfos?.user?.fullName}</TittlePageName>

        <ButtonGradientEdit
          onClick={handleGearClick}
          text={"Gerenciar perfis"}
          width={290}
          height={45}
          fontSize={20}
        />
      </MidPosition>
      <Footer/>
    </Body>
  );
};

export default Home;
