import React from "react";
import InfoContent from "./InfoContent";
import floresRaras from "../../../assests/imgs/Gloria-Pires.png";
import imagineYou from "../../../assests/imgs/imagine-you-and-me.png";
import bottoms from "../../../assests/imgs/Bottoms.png";
import loveLies from "../../../assests/imgs/Love-Liers-Bleeding.jpg";

const Info = () => {
  const responsiveOptions = [
    { breakpoint: "1400px", numVisible: 2, numScroll: 1 },
    { breakpoint: "1199px", numVisible: 3, numScroll: 1 },
    { breakpoint: "767px", numVisible: 2, numScroll: 1 },
    { breakpoint: "575px", numVisible: 1, numScroll: 1 },
  ];

  const mockProducts = [
    { id: 1, name: "Flores Raras", image: floresRaras },
    { id: 2, name: "Imagine Me and You", image: imagineYou },
    { id: 3, name: "Bottoms", image: bottoms },
    { id: 4, name: "Love Lies Bleeding", image: loveLies },
  ];

  const productTemplate = (product) => (
    <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
      <div className="mb-3">
        <img
          style={{ width: "96%", height: "214px", borderRadius: "20px" }}
          src={product.image}
          alt={product.name}
          className="w-6 shadow-2"
        />
      </div>
    </div>
  );

  return <InfoContent products={mockProducts} responsiveOptions={responsiveOptions} productTemplate={productTemplate} />;
};

export default Info;
