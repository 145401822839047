import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 0.5;
    }
    to {
        opacity: 0;
    }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent; /* Altere conforme necessário */
  overflow: hidden;

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    opacity: 0; // Começa invisível
    overflow: hidden;
    animation: ${fadeIn} 1s forwards; // Aplica a animação de fade-in

    &.fade-out {
      animation: ${fadeOut} 1s forwards; // Aplica a animação de fade-out
    }
  }
`;

export const Logo = styled.video`
  width: 100px; /* Ajuste o tamanho da logo conforme necessário */
  opacity: 75%;
`;
