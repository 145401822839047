import { Carousel } from "primereact/carousel";
import React from "react";

const CarouselComponent = ({ products, productTemplate, responsiveOptions }) => (
  <div className="card">
    <Carousel
      value={products}
      numVisible={4}
      numScroll={1}
      responsiveOptions={responsiveOptions}
      className="custom-carousel"
      circular
      data-pr-autohide
      itemTemplate={productTemplate}
    />
  </div>
);

export default CarouselComponent;
