import React, { useContext, useEffect, useState } from "react";
import { Body, Card } from "./styled";
import GlobalStateContext from "../../../GlobalState/GlobalStateContext";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useNavigate } from "react-router-dom";
import Footer from "../../../Components/Footer";



const Support = () => {
    const { userInfos } = useContext(GlobalStateContext);
    const navigate = useNavigate();

    const [tabs] = useState([
        {
            header: 'Title I',
            children: <p className="m-0">Content 1 </p>
        },
        {
            header: 'Title II',
            children: <p className="m-0">Content 2</p>
        },
        {
            header: 'Title III',
            children: <p className="m-0">Content 3</p>
        },
        {
            header: 'Title IV',
            children: <p className="m-0">Content 4</p>
        },
        {
            header: 'Title V',
            children: <p className="m-0">Content 5</p>
        }
    ]);

    const createDynamicTabs = () => {
        return tabs.map((tab, i) => {
            return (
                <AccordionTab key={tab.header} header={tab.header} disabled={tab.disabled}>
                    {tab.children}
                </AccordionTab>
            );
        });
    };

    useEffect(() => {
        console.log("aqui", userInfos);
    }, [userInfos]);

    return (
        <Body>
            <h1 style={{ color: "#fff"}}>Central de Ajuda</h1>
            <Card>
                <Accordion>{createDynamicTabs()}</Accordion>
            </Card>
            <Footer/>
        </Body>
    );
}

export default Support;
