import styled from "styled-components";


export const Foter = styled.footer`
width: 100%; 
text-align: center;
margin: 10px;
`;

export const BodyText = styled.div`

display: flex;
gap: 20px; 
justify-content: center;
align-items: center;
color: white;


  @media (max-width: 768px) {
    flex-direction: column; 
    gap: 5px; 
  }

`;

