import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import logosemnome from "../../assests/imgs/simbolosemnome.png";
import logocomnome from "../../assests/imgs/logocomnome.png";
import sino from "../../assests/imgs/Sino.png";
import profile from "../../assests/imgs/profile.png";
import ButtonGradientEdit from "../Buttons/ButtonGradientEdit";
import GlobalStateContext from "../../GlobalState/GlobalStateContext";
import { animated, useSpring } from "@react-spring/web";
import { InputText } from "primereact/inputtext";
import { StyledMenu } from "./styled";
import { FaBars } from "react-icons/fa";

const Header = styled(animated.div)`
  width: 100%;
  position: fixed;
  top: 0;
  transition: top 0.3s;
  z-index: 10;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 3), rgba(0, 0, 0, 0));
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 100px;
  margin-left: 40px;
  cursor: pointer;
  position: relative;
`;

const Image = styled.div`
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: display 0.3s ease;

  &:first-child {
    background-image: url(${logosemnome});
    display: block;
  }

  &:last-child {
    background-image: url(${logocomnome});
    display: none;
  }

  ${ImageContainer}:hover & {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
`;

const ImagesemNome = styled.div`
  height: 100px;
  width: 55px;
  background-image: url(${logosemnome});
  background-size: cover;
  transition: background-image 0.3s ease;
  margin-left: 100px;

  &:hover {
    background-image: url(${logocomnome});
    width: 200px;
    height: 100px;
  }
`;


const Text = styled.div`
  color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 19px;
  letter-spacing: 0.2em;
  cursor: pointer;
  margin: 0 10px;

  :hover {
    opacity: 0.5;
  }

  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;

const Body = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImgPorfile = styled.img`
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
`;
const DetailsPanel = styled.div`
  background: white;
  color: black;
  padding: 20px;
  position: absolute;
  width: 300px;
  right: 0;
  top: 105%;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;

  p {
    margin: 10px 0;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  margin-right: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

    @media (max-width: 425px) {
      margin-right: 20px;
  }
`;

const SearchInputWrapper = styled(animated.div)`
  position: absolute;
  right: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    gap: 50px;
    padding: 20px;
    z-index: 10;
  }
`;


const HamburgerIcon = styled.div`
  display: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 24px;
  margin-right: 20px;
  z-index: 15;

  @media (max-width: 1024px) {
    display: block;
  }

  div {
    background-color: white;
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;

    &:nth-child(1) {
      top: ${({ isOpen }) => (isOpen ? "10px" : "0")};
      transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      top: 10px;
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }
    &:nth-child(3) {
      top: ${({ isOpen }) => (isOpen ? "10px" : "20px")};
      transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const MenuWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen || window.innerWidth > 1024 ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin-top: 50px;
  gap: 50px;
  align-items: center;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 10;

    @media (min-width: 1024px) {
    position: relative;
    top: auto;
    margin-top: 0;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ContainerHamburgerIcon = styled.div`
  display: flex;
  align-items: center; flex-direction: column;

    @media (max-width: 768px) {
      position: fixed;
      top: 150px;
      left: 50px;
  }
`;




const MenuHome = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const { logged, setLogged, userInfos } = useContext(GlobalStateContext);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const fade = useSpring({
    from: { opacity: 0, x: -100 },
    to: { opacity: 1, x: 0 },
    config: { duration: 1000 },
  });

  const onNodeSelect = (e) => {
    const nodeData = e.node.data;
    if (nodeData) {
      navigate(nodeData);
    }
  };

  const [nodes] = useState([]);
  const menuRef = useRef(null);


  const logOut = () => {
    setLogged(false);
    localStorage.setItem("@token", null);
  }

  // Definindo o array de itens para o menu
  const [items] = useState([
    {
      label: "Gerenciamento de Conta",
      icon: "pi pi-user",
      items: [
        {
          label: "Alternar Perfil",
          icon: "pi pi-user-edit",
          command: () => navigate("/home"),
        },
        {
          label: "Gerenciar Contas",
          icon: "pi pi-cog",
          command: () => navigate("/manege"),
        },
        // ...(userInfos?.user?.typeUser === 3
        //   ? [
        //       {
        //         label: "Página Administrativa",
        //         icon: "pi pi-directions-alt",
        //         command: () => navigate("/upfilm"),
        //       },
        //     ]
        //   : []),

        // {
        //   label: "Métodos de Pagamento",
        //   icon: "pi pi-credit-card",
        //   command: () => navigate("/payamentMethod"),
        // },
        {
          label: "Sair",
          icon: "pi pi-sign-out",
          command: () => logOut(),
        },
      ],
    },
    {
      label: "Ferramentas Exclusivas",
      icon: "pi pi-tool",
      items: [
        {
          label: "Recompensas",
          icon: "pi pi-star",
          command: () => navigate("/fidelity"),
        },
        {
          label: "Criar Sala de Cinema",
          icon: "pi pi-video",
          command: () => navigate("/roomCinema"),
        },
      ],
    }, {
      label: "Suporte ao Cliente",
      icon: "pi pi-tool",
      command: () => navigate("/support")
    }
  ]);

  const [showDetails, setShowDetails] = useState(false);

  const searchInputStyle = useSpring({
    width: showSearchInput ? 150 : 0,
    opacity: showSearchInput ? 1 : 0,
    config: { tension: 220, friction: 12 },
  });

  useEffect(() => {
    console.log(userInfos)
  }, [userInfos])

  useEffect(() => {
    const onScroll = () => {
      const isTop = window.pageYOffset < 50;
      setHasScrolled(!isTop);
      if (isTop) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);


  const toggleDetails = () => setShowDetails(!showDetails);


  useEffect(() => {
    const onMouseMove = (event) => {
      if (hasScrolled) {
        const shouldBeVisible = event.clientY <= 100;
        setIsVisible(shouldBeVisible);
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    return () => document.removeEventListener("mousemove", onMouseMove);
  }, [hasScrolled]); // Dependência para reagir às mudanças do estado hasScrolled.

  const headerStyle = useSpring({
    to: {
      transform: isVisible ? `translateY(0)` : `translateY(-100%)`,
      opacity: isVisible ? 1 : 0,
      config: { mass: 1, tension: 210, friction: 20 },
    },
  });

  const handleClick = (e) => {
    setShowDetails(!showDetails); // Chama a função toggleDetails
    if (menuRef.current) {
      menuRef.current.toggle(e); // Chama o método toggle do menu
    } else {
      console.error("Menu reference is null");
    }
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Header style={headerStyle}>
      <Body>
        <ImageContainer onClick={handleLogoClick}>
          <Image style={{ height: 100, width: 55 }} /> {/* Imagem sem nome */}
          <Image /> {/* Imagem com nome */}
        </ImageContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <animated.div style={fade}>
            <ContainerHamburgerIcon >
              <HamburgerIcon onClick={toggleMenu} isOpen={menuOpen}>
                <div />
                <div />
                <div />
              </HamburgerIcon>

              <MenuWrapper isOpen={menuOpen}>
                <Text onClick={() => navigate("/")}>Início</Text>
                <Text onClick={() => navigate("/series")}>Séries</Text>
                <Text onClick={() => navigate("/films")}>Filmes</Text>
                <Text onClick={() => navigate("/exclusives")}>Exclusivos</Text>
                <Text onClick={() => navigate("/canais")}>Canais</Text>
              </MenuWrapper>
            </ContainerHamburgerIcon>

            {showDetails && (
              <div
                className="card"
                style={{
                  position: "absolute",
                  right: 0,
                  backgroundColor: "black",
                }}
              ></div>
            )}
          </animated.div>
        </div>
        <div
          style={{
            marginRight: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchContainer>
            <span className="p-input-icon-left" onClick={() => setShowSearchInput(!showSearchInput)} style={{ cursor: 'pointer' }}>
              <i style={{ marginLeft: 5, color: 'white' }} className="pi pi-search" />
            </span>
            <SearchInputWrapper style={{ ...searchInputStyle, overflow: 'hidden' }}>
              <InputText style={{ backgroundColor: "transparent", color: "white", width: "100%", paddingLeft: 20 }} placeholder="Pesquisar" />
            </SearchInputWrapper>
          </SearchContainer>

          {logged ? (
            <>
              <img style={{ height: 33, marginLeft: 10 }} src={sino} />

              <span className="p-input-icon-left"
                onMouseEnter={handleClick}
                style={{ cursor: 'pointer', marginLeft: 10 }}>
                <i style={{ marginLeft: 3, color: 'white' }} className="pi pi-user" />
              </span>
              <StyledMenu model={items} popup ref={menuRef}
                onMouseLeave={handleClick}
              />
            </>

          ) : (
            <ButtonGradientEdit
              onClick={() => navigate("/login")}
              text={"Login"}
              width={100}
              height={20}
            />
          )}
        </div>
      </Body>
    </Header>
  );
};

export default MenuHome;
